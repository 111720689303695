import { ReactNode } from 'react';
import Text, { TypeVariant } from 'shared/Typography';
import styled from 'styled-components';

/**
 * This file has all the generic styles that are used to compose the text and layout of
 * the creator landing page
 * It has 3 sections
 * 1. Typography
 * 2. Generic Layout - used in all files
 * 3. Upcoming Sessions & Programs/challenges Layout
 */

interface Props {
  children: ReactNode | ReactNode[];
}

// TYPOGRAPHY

export const SectionTitle = ({ children }: Props) => (
  <Text
    variant={TypeVariant.header02}
    as="h2"
    color="var(--color-black-500)"
    tablet={TypeVariant.header03}
    phone={TypeVariant.subtitle01}
    textAlign={{
      default: 'left',
      phone: 'center',
    }}
  >
    {children}
  </Text>
);

export const HeaderText = ({ children }: Props) => (
  <Text
    variant={TypeVariant.bodyLarge01Bold}
    as="h3"
    color="var(--color-black-500)"
    textAlign={{
      default: 'left',
      phone: 'center',
    }}
    maxLines={1}
  >
    {children}
  </Text>
);

export const DetailText = ({ children }: Props) => (
  <Text
    variant={TypeVariant.bodyLarge01}
    as="p"
    color="var(--color-black-500)"
    phone={TypeVariant.body01}
    textAlign={{
      default: 'left',
      phone: 'center',
    }}
    maxLines={{
      default: 3,
      phone: 4,
    }}
  >
    {children}
  </Text>
);

export const RichDetailText = styled.div`
  font-family: 'Epilogue';
  font-size: var(--font-size-body-large-01);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-md);
  color: var(--color-black-500);
  /* since we can't predict which will be last element (p, ol, ul)
    adding negative margin to accomdate for spacing
  */
  /* margin-bottom: -12px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    margin-left: auto;
    margin-right: auto;
    font-size: var(--font-size-body-01);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-sm);
    letter-spacing: var(--letter-spacing-sm);

    display: block;
  }

  h2,
  h3,
  h4,
  p,
  a,
  i,
  strong {
    font-family: inherit;
    margin-bottom: var(--spacing-12);
  }

  a {
    color: var(--color-primary-500);
  }

  ol {
    list-style: revert;
    font-family: inherit;
    margin-bottom: var(--spacing-12);
    padding-left: var(--spacing-32);

    li {
      font-family: inherit;
      &:not(:last-child) {
        margin-bottom: var(--spacing-4);
      }
    }
  }

  ul {
    list-style: revert;
    font-family: inherit;
    margin-bottom: var(--spacing-12);
    padding-left: var(--spacing-32);

    li {
      font-family: inherit;
      &:not(:last-child) {
        margin-bottom: var(--spacing-4);
      }
    }
  }
`;

export const CaptionText = ({ children }: Props) => (
  <Text
    variant={TypeVariant.body02}
    as="p"
    color="var(--color-gray-700)"
    textAlign={{
      default: 'left',
      phone: 'center',
    }}
  >
    {children}
  </Text>
);

// GENERIC LAYOUT USED IN ALL FILES

export const DesktopMediaContainer = styled.div`
  flex: 1 1 50%;
  align-self: center;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

export const AspectRatioBox = styled.div`
  width: 100%;
  padding-top: calc((9 / 16) * 100%);
  position: relative;

  @media ${(p) => p.theme.queries.laptopAndDown} {
    padding-top: 400px;
  }
`;

export const ImageAbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const MobileMediaContainer = styled.div`
  display: none;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
    position: relative;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    align-items: center;
  }
`;

export const SessionContainerBase = styled.section`
  display: flex;
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;

  @media ${(p) => p.theme.queries.laptopAndDown} {
    max-width: 100%;
    margin: 0;
  }
`;

export const MainContainer = styled.div`
  align-self: center;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    align-self: flex-start;
    align-items: center;
    padding: 0;
    gap: var(--spacing-32);
  }
`;

export const LeftMainContainer = styled(MainContainer)`
  padding-left: var(--spacing-64);
  padding-right: var(--spacing-48);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 0;
  }
`;

export const RightMainContainer = styled(MainContainer)`
  padding-left: var(--spacing-96);
  padding-right: var(--spacing-64);

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding-left: var(--spacing-48);
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 0;
  }
`;

// UPCOMING SESSIONS AND PROGRAMS SPECIFIC LAYOUT

export const LoadingWrap = styled.div`
  width: 100%;
  /* min-height: 400px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
  width: 100%;
`;

export const HeaderRow = styled.div`
  padding-bottom: var(--spacing-16);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-24);
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 0 30px;
  }
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
`;

export const DetailsRow = styled.div`
  display: flex;
  gap: var(--spacing-16);
  &:not(:last-child) {
    padding-bottom: var(--spacing-16);
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    align-items: center;
  }
`;
export const DetailsWithIconWrap = styled.div`
  @media ${(p) => p.theme.queries.phoneAndDown} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowButton = styled.button`
  border: none;
  background: var(--color-secondary-500);
  cursor: pointer;
  --chevron-color: var(--color-white);

  width: 40px;
  height: 40px;
  border-radius: 999px;

  &:disabled {
    background: none;
    border: 2px solid var(--color-gray-300);
    --chevron-color: var(--color-gray-300);
  }
`;

export const MobileArrowButton = styled.button`
  display: none;
  border: none;
  background: none;
  cursor: pointer;
  --chevron-color: var(--color-black-700);

  &:disabled {
    --chevron-color: var(--color-gray-300);
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
  }
`;
