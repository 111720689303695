import { PageConfigResponse } from '@solin-fitness/types';
import Head from 'next/head';
import { buildImageUrl } from 'cloudinary-build-url';

type Props = {
  pageConfig?: PageConfigResponse;
};

const MetaTagsHead = ({ pageConfig }: Props) => {
  const adminUser = pageConfig?.users.find((u) => u.type === 2);

  const title = adminUser
    ? `${adminUser?.profile.firstName} ${adminUser?.profile.lastName} | Solin Stream`
    : !pageConfig
    ? 'Solin Stream'
    : `${pageConfig.config.pageName} | Solin Stream`;

  const metaImage = buildImageUrl(
    pageConfig?.config.creatorImagePublicId || '',
    {},
  );

  return (
    <Head>
      <title>{title}</title>
      <meta
        property="og:title"
        content={pageConfig?.config.pageName}
        key="title"
      />
      <meta
        name="og:description"
        key="description"
        content={pageConfig?.config.pageDescription}
      />
      <meta property="og:image" key="image" content={metaImage} />
      <meta name="description" content={pageConfig?.config.pageDescription} />
    </Head>
  );
};

export default MetaTagsHead;
