import { motion } from 'framer-motion';
import { addEmailToEmailList } from 'queries/page';
import React, { FormEvent, ReactNode, useState } from 'react';
import { useMutation } from 'react-query';
import Button from 'shared/Button';
import { IconNames } from 'shared/Icons';
import Spacer, { Size } from 'shared/Spacer';
import TextInput from 'shared/TextInput';
import Typography, { TypeVariant } from 'shared/Typography';
import styled from 'styled-components';

interface TypeProps {
  children: ReactNode | ReactNode[];
  color: string;
}

const HeaderText = ({ children, color }: TypeProps) => (
  <Typography
    variant={TypeVariant.subtitle02}
    as="h3"
    color={color}
    textAlign="left"
  >
    {children}
  </Typography>
);

interface Props {
  size: 'small' | 'large';
  creator: string | undefined;
  page: string;
}

const EmailListFormMobile = ({ size, creator, page }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const color =
    size === 'small' ? 'var(--color-white)' : 'var(--color-black-500)';

  const textInputVariant = size === 'small' ? 'light' : 'dark';

  const text = creator ? `${creator}'s` : 'the';

  const handleAddEmailToEmailList = useMutation((emailValue: string) =>
    addEmailToEmailList(emailValue, page),
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    await handleAddEmailToEmailList.mutateAsync(email);
    setEmail('');
    setSubmitting(false);
    setSubmitted(true);
  };

  return (
    <Container onSubmit={(e) => handleSubmit(e)}>
      <MotionWrapper
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <HeaderText color={color}>Join {text} Email List!</HeaderText>
        <Typography
          variant={TypeVariant.bodyLarge01Bold}
          as="p"
          color={color}
          textAlign="left"
        >
          Don't Miss Upcoming Offerings and Specials
        </Typography>
      </MotionWrapper>
      <MotionWrapper
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <TextInput
          fullWidth
          variant="rounded"
          type="text"
          background={textInputVariant}
          label="Enter your email"
          value={email}
          onChange={(val) => {
            if (submitted) {
              setSubmitted(false);
            }
            setEmail(val);
          }}
          autoCapitalize={false}
        />
      </MotionWrapper>
      <Spacer size={Size.spacing4} />
      <MotionWrapper
        initial={{ x: '-150%' }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', duration: 0.75, delay: 1 }}
      >
        {submitted ? (
          <Button
            variant="fill"
            size="medium"
            disabled={!!!email}
            isLoading={isSubmitting}
            withIcon
            iconLeft
            iconId={IconNames.check}
          >
            Submitted
          </Button>
        ) : (
          <Button
            variant="fill"
            size="medium"
            disabled={!!!email}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        )}
      </MotionWrapper>
    </Container>
  );
};

const Container = styled.form`
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-8);
`;

const MotionWrapper = styled(motion.div)`
  display: none;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
    width: 100%;
  }
`;

export default EmailListFormMobile;
