import { api, Methods } from '../services/api';
import {
  Community,
  PostDonationResponse,
  CommunityPostRequest,
  CommunityEditRequest,
  CommentRequest,
} from '@solin-fitness/types';
import { useQuery } from 'react-query';
import { communityKeys } from './community';

export interface SendApprovalRequest {
  socialMediaUrl?: string | undefined;
  email?: string | undefined;
}
export const sendCreatorApproval = async (data: SendApprovalRequest) => {
  await api(Methods.post, '/page/approval', data, {});
};

export const addEmailToEmailList = async (email: string, page: string) => {
  await api(
    Methods.post,
    '/page/email',
    {
      email,
    },
    { xPage: page },
  );
};

export const fetchPageCommunityPosts = async (pageId: number) =>
  await api<Community>(Methods.get, `/page/${pageId}/post`);

export const usePageCommunityPosts = (pageId: number) =>
  useQuery(communityKeys.pageAll(pageId), () =>
    fetchPageCommunityPosts(pageId),
  );

export const fetchPageDonations = async (pageId: number) =>
  await api<PostDonationResponse[]>(Methods.get, `/page/${pageId}/donations`);

export const usePageDonations = (pageId: number) =>
  useQuery(communityKeys.pageDonations(pageId), () =>
    fetchPageDonations(pageId),
  );

export const createCommunityPostPage = async (
  params: CommunityPostRequest,
  id: number,
) => await api(Methods.post, `/page/${id}/post`, params);

export const editPostPage = async (
  entityId: number,
  postId: number,
  data: CommunityEditRequest,
) => await api(Methods.patch, `/page/${entityId}/post/${postId}`, data);

export const deletePostPage = async (entityId: number, postId: number) =>
  await api(Methods.delete, `/page/${entityId}/post/${postId}`);

export const addPostCommentPage = async (
  params: CommentRequest,
  pageId: number,
  postId: number,
) => await api(Methods.post, `/page/${pageId}/post/${postId}/comment`, params);

export const editPostCommentPage = async (
  pageId: number,
  postId: number,
  commentId: number,
  data: CommunityPostRequest,
) =>
  await api(
    Methods.patch,
    `/page/${pageId}/post/${postId}/comment/${commentId}`,
    data,
  );
