import {
  CalendarResponse,
  PageConfigResponse,
  PageSubscriptions,
  UserMetaDataResponse,
  UserType,
} from '@solin-fitness/types';
import React from 'react';
import CreatorLandingPageBanner from './Components/CreatorLandingPageBanner';
import styled from 'styled-components';
import YourAccessSection from './Components/YourAccessSection';
import CreatorUpcomingSessions from './Components/CreatorUpcomingSessions';
import CreatorPrograms from './Components/CreatorPrograms';
import CreatorLearnMore from './Components/CreatorLearnMore';
import CreatorTestimonialsSection from './Components/CreatorTestimonialsSection';
import Spacer, { Size } from 'shared/Spacer';
import Footer from 'components/Footer/Footer';
import { sort } from 'ramda';
import EmailListForm from './Components/EmailListForm';
import { usePublicCalendar } from 'queries/calendar';
import { usePrograms } from 'queries/programs';
import { Spinner } from 'shared/Spinner';
import { LoadingWrap } from './Components/CreatorLandingPage.styles';
import { getSectionOrder } from './Components/creatorLandingPageHelpers';
import { DateTime } from 'luxon';
import { SubscriptionStatus } from '@solin-fitness/types';
import { useRouter } from 'next/router';
import { reactivateSubscription } from 'queries/user';
import { useMutation, useQueryClient } from 'react-query';
import { ReactivateSubscriptionModal } from './Components/ReactivateSubscriptionModal';

const removePastCalendarItemsAndSort = (
  items: CalendarResponse[],
): CalendarResponse[] => {
  const currentDate = new Date();
  const upcomingCalendarItems = items.filter((item) => {
    const date = DateTime.fromJSDate(new Date(item.date))
      .plus({ minutes: item.data.length })
      .toJSDate();

    return date > currentDate;
  });

  const sortedCalendarItems = sort(
    (a: CalendarResponse, b: CalendarResponse) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();
      return aDate - bDate;
    },
    upcomingCalendarItems,
  );
  return sortedCalendarItems;
};

const findLowestSubscription = (subscriptions: PageSubscriptions[]) => {
  const activeSubscriptions = subscriptions.filter((sub) => sub.isActive);
  const sortedSubscriptions = sort(
    (a: PageSubscriptions, b: PageSubscriptions) => {
      const aPrice = a.amount;
      const bPrice = b.amount;
      return aPrice - bPrice;
    },
    activeSubscriptions,
  );

  return sortedSubscriptions[0];
};
interface Props {
  config: PageConfigResponse;
  metadata: UserMetaDataResponse | undefined;
}

const CreatorLandingPage = ({ config, metadata }: Props) => {
  const router = useRouter();

  const {
    url,
    id,
    config: {
      pageDescription,
      trialPeriodDays,
      lightLogoPublicId,
      backgroundImage1PublicId,
      backgroundImage2PublicId,
      backgroundImage3PublicId,
      teaserThumbnailPublicId,
      teaserVideo,
      landingPageListItems,
      learnMoreImagePublicId,
      verticalBackgroundImage,
      bio,
      subscriptions,
      testimonials,
    },
  } = config;

  const subscriptionsToPage = metadata?.subscriptions.filter(
    (sub) =>
      sub.pageId === id &&
      (sub.status === SubscriptionStatus.canceled ||
        sub.status === SubscriptionStatus.incompleteExpired),
  );

  const hasCanceledSubscription = !!subscriptionsToPage?.length;

  const lowestSubscription = findLowestSubscription(subscriptions);

  const {
    isLoading: isLoadingCalendar,
    data: upcomingSessions,
  } = usePublicCalendar(url, 1, 2, removePastCalendarItemsAndSort);

  const { isLoading: isLoadingPrograms, data: programsRaw } = usePrograms(
    url,
    undefined,
  );

  const programs = (programsRaw || [])
    .filter((program) => program.published)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  const adminCreator = config.users.find(
    (user) => user.type === UserType.adminCreator,
  );

  const creatorName = adminCreator?.profile.firstName;
  const creatorFullName = `${adminCreator?.profile?.firstName} ${adminCreator?.profile?.lastName}`;

  const queryClient = useQueryClient();
  const reactivate = useMutation((subscriptionId: string) =>
    reactivateSubscription(subscriptionId),
  );
  const handleReactivate = () => {
    if (subscriptionsToPage?.length) {
      reactivate.mutate(subscriptionsToPage[0].subscriptionId, {
        onSuccess: () => {
          queryClient.invalidateQueries('metadata');
          router.push(url);
        },
      });
    }
  };
  const isOpenReactivateModal = !!router.query.reactivate;
  const closeReactivateModal = () => {
    router.push(url, undefined, { shallow: true });
  };

  return (
    <>
      <CreatorLandingPageBanner
        page={url}
        pageId={id}
        logo={lightLogoPublicId}
        verticalBackgroundImage={verticalBackgroundImage}
        backgroundImage={backgroundImage1PublicId}
        description={pageDescription}
        hasSubscription={!!lowestSubscription}
        purchasedLiveWorkouts={metadata?.purchasedClasses || []}
        purchasedEvents={metadata?.purchasedEvents || []}
        trialPeriod={trialPeriodDays}
        creator={creatorFullName}
        hasCanceledSubscription={!!hasCanceledSubscription}
      />

      <DesktopBannerSpacer />

      {isLoadingPrograms || isLoadingCalendar ? (
        <LoadingWrap>
          <Spinner
            size="large"
            color="var(--color-primary-500)"
            ringColor="var(--color-gray-500)"
          />
        </LoadingWrap>
      ) : (
        (() => {
          const hasSubscription = !!lowestSubscription;
          const hasUpcomingSessions = !!upcomingSessions?.length;
          const hasPrograms = !!programs?.length;
          const hasTestimonials = !!testimonials?.length;

          const sectionOrder = getSectionOrder(
            hasSubscription,
            hasUpcomingSessions,
            hasPrograms,
            hasTestimonials,
          );

          return (
            <>
              <YourAccessSection
                page={url}
                image={teaserThumbnailPublicId}
                video={teaserVideo}
                details={landingPageListItems}
                hasSubscription={!!lowestSubscription}
                trialPeriod={trialPeriodDays}
                hasCanceledSubscription={!!hasCanceledSubscription}
              />

              {hasSubscription ? <SectionSpacer /> : null}

              <CreatorUpcomingSessions
                page={url}
                image={backgroundImage2PublicId}
                imageRight={sectionOrder[1]}
                upcomingSessions={upcomingSessions || []}
                hasSubscription={!!lowestSubscription}
                purchasedLiveWorkouts={metadata?.purchasedClasses || []}
                purchasedEvents={metadata?.purchasedEvents || []}
                trialPeriod={trialPeriodDays}
                hasCanceledSubscription={!!hasCanceledSubscription}
              />

              {hasUpcomingSessions ? <SectionSpacer /> : null}

              {programs && programs.length > 0 && (
                <CreatorPrograms
                  page={url}
                  image={backgroundImage3PublicId}
                  imageRight={sectionOrder[2]}
                  programs={programs || []}
                  purchasedPrograms={metadata?.purchasedPrograms || []}
                  hasSubscription={!!lowestSubscription}
                />
              )}

              {hasPrograms ? <SectionSpacer /> : null}

              <CreatorLearnMore
                page={url}
                image={learnMoreImagePublicId}
                imageRight={sectionOrder[3]}
                bio={bio}
                hasSubscription={!!lowestSubscription}
                trialPeriod={trialPeriodDays}
                hasCanceledSubscription={!!hasCanceledSubscription}
              />

              {hasTestimonials ? <SectionSpacer /> : null}

              <CreatorTestimonialsSection
                page={url}
                noBackground={sectionOrder[4]}
                testimonials={testimonials}
                hasSubscription={!!lowestSubscription}
                trialPeriod={trialPeriodDays}
                hasCanceledSubscription={!!hasCanceledSubscription}
              />

              {!hasSubscription ? <SectionSpacer /> : null}

              {!!!hasSubscription ? (
                <EmailListSection
                  style={{
                    '--background-color': sectionOrder[5]
                      ? undefined
                      : 'var(--color-off-white-500)',
                  }}
                >
                  <EmailListWrap>
                    <EmailListForm
                      size="large"
                      creator={creatorName}
                      page={url}
                    />
                  </EmailListWrap>
                </EmailListSection>
              ) : null}

              <FooterWrap>
                <Spacer
                  size={Size.spacing96}
                  axis="vertical"
                  color={
                    sectionOrder[6] ? undefined : 'var(--color-off-white-500)'
                  }
                />
                <Footer
                  background={
                    sectionOrder[6] ? undefined : 'var(--color-off-white-500)'
                  }
                />
              </FooterWrap>

              {hasCanceledSubscription ? (
                <ReactivateSubscriptionModal
                  isOpen={isOpenReactivateModal}
                  onDismiss={closeReactivateModal}
                  onReactivate={handleReactivate}
                  isLoading={reactivate.isLoading}
                />
              ) : null}
            </>
          );
        })()
      )}
    </>
  );
};

const DesktopBannerSpacer = styled.div`
  padding-bottom: var(--spacing-80);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: 0;
  }
`;

const SectionSpacer = styled.div`
  padding-bottom: var(--spacing-160);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: 0;
  }
`;

const FooterWrap = styled.div`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
`;

const EmailListSection = styled.section`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--background-color);
  padding-top: var(--spacing-48);
`;

const EmailListWrap = styled.div`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

export default CreatorLandingPage;
