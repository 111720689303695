import {
  Community,
  CommunityPostResponse,
  PinnedPostRequest,
} from '@solin-fitness/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, Methods } from '../services/api';
import { useRouter } from 'next/router';

export const communityKeys = {
  all: ['community'] as const,
  pageAll: (id: number | string) =>
    [...communityKeys.all, 'page', Number(id)] as const,
  programAll: (id: number | string) =>
    [...communityKeys.all, 'program', Number(id)] as const,
  byPostId: (id: number | string) =>
    [...communityKeys.all, Number(id)] as const,
  pageDonations: (id: number | string) =>
    [...communityKeys.pageAll(id), 'donations'] as const,
  programDonations: (id: number | string) =>
    [...communityKeys.programAll(id), 'donations'] as const,
};

const fetchProgramPostById = async (
  postId: number | string,
  entityId: number | string,
) =>
  await api<CommunityPostResponse>(
    Methods.get,
    `/programs/${entityId}/post/${postId}`,
  );

const fetchPagePostById = async (
  postId: number | string,
  entityId: number | string,
) =>
  await api<CommunityPostResponse>(
    Methods.get,
    `/page/${entityId}/post/${postId}`,
  );

export const fetchCommunityPostsProgram = async (programId: number) =>
  await api<Community>(Methods.get, `/programs/${programId}/post`);

export const usePostById = (
  postId: number | string,
  entityId: number | string,
) => {
  const router = useRouter();
  const isProgram = router.pathname.includes('program');
  const handler = isProgram ? fetchProgramPostById : fetchPagePostById;

  return useQuery(communityKeys.byPostId(postId), () =>
    handler(postId, entityId),
  );
};

export const useProgramPosts = (programId: number) =>
  useQuery(communityKeys.programAll(programId), () =>
    fetchCommunityPostsProgram(programId),
  );

const pinPagePost = async (
  entityId: number | string,
  data: PinnedPostRequest,
) => api(Methods.post, `/page/${entityId}/post/pin`, data);

const pinProgramPost = async (
  entityId: number | string,
  data: PinnedPostRequest,
) => api(Methods.post, `/programs/${entityId}/post/pin`, data);

export const usePinPost = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const isProgram = router.pathname.includes('program');

  const handler = isProgram ? pinProgramPost : pinPagePost;

  return useMutation(
    ({
      entityId,
      data,
    }: {
      entityId: number | string;
      data: PinnedPostRequest;
    }) => handler(entityId, data),
    {
      // onMutate: ({ entityId, data }) => {
      //   if (isProgram) {
      //     const oldCommunity = queryClient.getQueryData<Community>(
      //       communityKeys.programAll(entityId),
      //     );
      //     const updatedCommunity: Community = {
      //       pinnedPostId: data.postId,
      //       posts: oldCommunity?.posts || [],
      //     };
      //     queryClient.setQueryData<Community>(
      //       communityKeys.programAll(entityId),
      //       updatedCommunity,
      //     );
      //   } else {
      //     const oldCommunity = queryClient.getQueryData<Community>(
      //       communityKeys.pageAll(entityId),
      //     );
      //     const updatedCommunity: Community = {
      //       pinnedPostId: data.postId,
      //       posts: oldCommunity?.posts || [],
      //     };
      //     queryClient.setQueryData<Community>(
      //       communityKeys.pageAll(entityId),
      //       updatedCommunity,
      //     );
      //   }
      // },
      // onSuccess: (_, { entityId }) => {
      //   if (isProgram) {
      //     queryClient.invalidateQueries(communityKeys.programAll(entityId));
      //   } else {
      //     queryClient.invalidateQueries(communityKeys.pageAll(entityId));
      //   }
      // },
    },
  );
};
