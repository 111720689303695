import React, { useEffect, useMemo } from 'react';
import { getPage, usePageConfig } from 'queries/creatorPage';
import useUserMetadata from 'hooks/useUserMetadata';
import { hasSubscription as hasSubscriptionFn } from 'services/metadata';
import LogRocket from 'logrocket';
import { QueryClient } from 'react-query';
import { GetServerSideProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { dehydrate } from 'react-query/hydration';
import dynamic from 'next/dynamic';
import { UserMetaDataResponse } from '@solin-fitness/types';
import Loading from 'components/Loading/Loading';
import ErrorPage from 'components/ErrorPage';
import Header, { HeaderVariant } from 'shared/Header';
import CreatorLandingPage from 'components/CreatorLandingPage';
import useAuthStore from 'hooks/useAuthStore';
import MetaTagsHead from 'components/MetaTagsHead';

const IS_DEV = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';

const DynamicCreatorSubscribedPage = dynamic(
  () => import('../../components/CreatorPage/SubscribedPage/CreatorSubscribed'),
  { ssr: false },
);

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { creatorPage } = context.params as ParsedUrlQuery;
  const queryClient = new QueryClient();

  const page = creatorPage as string;
  await queryClient.prefetchQuery(['pageConfig', { page }], () =>
    getPage(page),
  );

  return {
    props: {
      page,
      dehydratedState: dehydrate(queryClient),
    },
  };
};

interface ServerProps {
  page: string;
}

const CreatorPage = ({ page }: ServerProps) => {
  const { isSignedIn, userId } = useAuthStore();

  const { data: metadata, isLoading: isLoadingMetada } = useUserMetadata();

  const {
    isLoading: isLoadingPage,
    data: pageConfig,
    error: pageConfigError,
  } = usePageConfig(page);

  const [hasSubscription, subscription] = useMemo(
    () =>
      hasSubscriptionFn(
        metadata,
        pageConfig?.config.subscriptions || [],
        isSignedIn,
      ),
    [metadata, pageConfig, isSignedIn],
  );

  useEffect(() => {
    if (!IS_DEV) {
      // Can we think of something better to do then havin all these NA values?
      // Also, are we even using any of these tools?
      LogRocket.identify(metadata?.email || '', {
        userId: userId || 'NA',
        pageUrl: pageConfig?.url || 'NA',
      });
      LogRocket.getSessionURL((sessionURL) => {
        window.heap?.track('LogRocket', { sessionURL });
      });

      // Note: do NOT pass generic default value to heap.identify call(s)
      if (userId) {
        window.heap?.identify(userId.toString());
      }
    }
  }, []);

  const loggedInAsPageCreator = pageConfig?.users.find(
    (user) => user.id === userId,
  );

  if (pageConfig) {
    return (
      <div>
        <MetaTagsHead pageConfig={pageConfig} />

        <Header variant={HeaderVariant.dark} />

        {isLoadingMetada || isLoadingPage ? (
          <Loading />
        ) : loggedInAsPageCreator || (isSignedIn && hasSubscription) ? (
          <>
            <DynamicCreatorSubscribedPage
              pageConfig={pageConfig}
              metadata={metadata as UserMetaDataResponse}
            />
          </>
        ) : (
          <CreatorLandingPage config={pageConfig} metadata={metadata} />
        )}
      </div>
    );
  }

  if (pageConfigError) {
    return (
      <>
        <Header variant={HeaderVariant.dark} />
        <ErrorPage status={500} />
      </>
    );
  }

  return (
    <>
      <Header variant={HeaderVariant.dark} />
      <Loading />
    </>
  );
};

export default CreatorPage;
